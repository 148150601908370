<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" ref="supplierPaymentForm" @submit.prevent="supplierpPayments">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" dark :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">Supplier Payment Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense justify="center">
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">P. Type</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="supplierPayment.payment_type"
                                                        :items="['Cash', 'Bank']"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1" v-if="supplierPayment.payment_type == 'Bank'">
                                                <v-col cols="3">Bank Acc.</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        @focus="$store.dispatch('bankAccount/getBankAccounts')"
                                                        :loading="$store.getters['bankAccount/loading']"
                                                        :items="$store.getters['bankAccount/bankAccounts']"
                                                        item-text="display_text"
                                                        item-value="id"
                                                        v-model="bank"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Supplier</v-col>
                                                <v-col cols="9">
                                                    <v-combobox 
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="selectedSupplier"
                                                        @focus="$store.dispatch('supplier/getSuppliers')"
                                                        :items="$store.getters['supplier/suppliers']"
                                                        :loading="$store.getters['supplier/loading']"
                                                        item-text="display_text"
                                                        item-value="id"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Due</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :value="$store.getters['supplier/supplierDue']"
                                                        readonly
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="3" class="text-right pr-2">Date</v-col>
                                                <v-col cols="9">
                                                    <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="0"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="supplierPayment.payment_date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                autocomplete="off"
                                                            >
                                                                <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="supplierPayment.payment_date"
                                                            @input="menu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Description</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="supplierPayment.payment_note"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Amount</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="supplierPayment.payment_amount"
                                                        :rules="[() => !!supplierPayment.payment_amount || 'Enter Amount']"
                                                        type="number"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row no-gutters>
                                                         <v-col cols="6" class="pr-1">
                                                            <v-btn height="30px" type="reset" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn height="30px" type="submit" dark block color="light-blue darken-2" :loading="saveLoading">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="supplierPaymentHeaders"
                    :items="$store.getters['supplier/payments']"
                    :loading="$store.getters['supplier/loading']"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Supplier Payment List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search supplier payment"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-if="userType != 'user'" v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editSupplierPayment(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="supplierId = item.id;$refs.confirmDialog.dialog = true" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
                <confirm-dialog ref="confirmDialog" @confirm="deleteSupplierPayment"></confirm-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import confirmDialog from "./../../components/Confirm";
export default {
    name: 'SupplierEntry',

    components: {
		"confirm-dialog": confirmDialog,
    },

    data: ()=> ({
        menu: false,
        selectedSupplier: null,
        saveLoading: false,
        supplierId: null,
        bank: null,
        supplierPayment: {
            id: null,
            supplier_id : null,
            bank_account_id : null,
            payment_type : '',
            payment_note : '',
            payment_date : new Date().toISOString().substr(0, 10),
            payment_amount: 0.00,
        },

        supplierPaymentHeaders: [
            { text: 'Sl', value: 'sl' },
            { text: 'Date', value: 'payment_date' },
            { text: 'Payment By', value: 'supplier.name' },
            { text: 'Payment Type', value: 'payment_type' },
            { text: 'Amount', value: 'payment_amount' },
            { text: 'Description', value: 'payment_note' },
            { text: 'Action', value: 'action' },
        ],
        userType: ''
    }),

    watch: {
        selectedSupplier: function (s) {
            if (s == null) return
            this.supplierPayment.supplier_id = s.id
            this.$store.dispatch('supplier/getSupplierDue', {supplier_id: s.id });
        },

        bank(bank) {
            if(bank == null) return;
            this.supplierPayment.bank_account_id = bank.id
        }
    },

    created() {
        this.getSupplierPayments();
        let userData = JSON.parse(localStorage.getItem('_userData'))
        this.userType = userData.user_type
    },

    methods: {
        getSupplierPayments() {
            this.$store.dispatch('supplier/getSupplierPayments');
        },

        supplierpPayments() {
            if(! this.validationForm()) {
                return;
            }

            if(this.supplierPayment.payment_type == '') {
                this.$store.dispatch('snackbar/error', "Select payment type");
                return;
            }

            if(this.supplierPayment.supplier_id == null) {
                this.$store.dispatch('snackbar/error', "Select supplier");
                return;
            }

            this.saveLoading = true;

            let success = this.$store.dispatch('supplier/saveSupplierPayment', this.supplierPayment);

            if(success) {
                this.resetForm();
            }
            this.saveLoading = false;
        },

        editSupplierPayment(supplierPayment) {
            Object.keys(this.supplierPayment).forEach(key => this.supplierPayment[key] = supplierPayment[key]);
            this.selectedSupplier = supplierPayment.supplier;
            this.bank = supplierPayment.bank_account;
        },

        deleteSupplierPayment () {
            this.$store.dispatch('supplier/deleteSupplierPayment', { id: this.supplierId });
            this.$refs.confirmDialog.dialog = false;
        },

        validationForm() {
            let isValid = true;
            this.$refs.supplierPaymentForm.validate();
            this.$refs.supplierPaymentForm.inputs.forEach(input => {
                if(input.hasError) isValid = false;
            })

            return isValid;
        },

        resetForm() {
            this.$refs.supplierPaymentForm.reset();
            this.$refs.supplierPaymentForm.resetValidation();
            this.supplierPayment.payment_type = ''; 
            this.selectedSupplier = null;
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>